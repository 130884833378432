import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'

function DownloadCounter() {

    useEffect(() => {
        axios.post('https://kammun.app/api/v3/user/download').then(() => {
            window.location.replace('/download.html')
        })
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                color: '#000',
            }}
        >
            <img
                style={{
                    width: '200px',
                    height: '200px',
                }}
                alt='Download'
                src='/LogoNav.svg'
            />
        </div>
    )
}

export default DownloadCounter